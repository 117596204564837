




import { defineComponent, useRoute, onMounted, ref, useContext } from '@nuxtjs/composition-api'
import { onSSR } from '@vue-storefront/core'
import { CacheTagPrefix, useCache } from '@vue-storefront/cache'
import IbexiaPage from '@/modules/catalog/pages/category/ibexia'
import CategoryPage from '@/modules/catalog/pages/category/category'
import { useTraverseCategory } from '@/modules/catalog/category/helpers/useTraverseCategory'
import type { CategoryTree } from '~/modules/GraphQL/types'

export default defineComponent({
  name: 'AbstractCategory',

  components: {
    CategoryPage,
    IbexiaPage,
  },
  setup() {
    const { getCategory, loadCategoryTree, isCategoryTreeLoaded } = useTraverseCategory()
    const route = useRoute()
    const { addTags } = useCache()
    const { error: nuxtError, app } = useContext()

    const { params: { slug } } = route.value
    const displayPageIbexia = ref<boolean>(false)
    const category = ref<CategoryTree | null>(null)

    const isExternalContent = async (): Promise<boolean> => {
      if (!isCategoryTreeLoaded.value) {
        await loadCategoryTree()
      }

      category.value = getCategory(route.value.path)

      if (!category.value.uid) {
        throw new Error('Category not found')
      }

      return category.value?.ext_uniq_id !== null && category.value?.ext_uniq_id !== undefined
    }

    const setTags = () => {
      if (category.value?.uid) {
        addTags([{ prefix: CacheTagPrefix.Category, value: category.value.uid }])
      }
    }

    onMounted(async () => {
      try {
        if (slug) {
          displayPageIbexia.value = await isExternalContent()
        }
      } catch (e) {
        return nuxtError({ statusCode: 404, message: app.i18n.t('Page not found') as string })
      }

      setTags()
    })

    onSSR(async () => {
      try {
        if (slug) {
          displayPageIbexia.value = await isExternalContent()
        }
      } catch (e) {
        return nuxtError({ statusCode: 404, message: app.i18n.t('Page not found') as string })
      }

      setTags()
    })

    return {
      displayPageIbexia
    }
  }
})
