export default `
  query getCategoryContentData($filters: CategoryFilterInput,$url: String) {
    categoryList(filters: $filters,url: $url) {
      uid
      display_mode
      category_type
      seo_bloc1
      seo_bloc2
      landing_page
      cms_block {
        identifier
        content
      }
    }
  }
`;
