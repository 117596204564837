































import { defineComponent, onUpdated, ref, ssrRef, useContext, useFetch, useRouter } from '@nuxtjs/composition-api';
import { onSSR } from '@vue-storefront/core';
import { CacheTagPrefix, useCache } from '@vue-storefront/cache';
import type { MetaInfo } from 'vue-meta';
import { useCategoryContent } from '~/modules/catalog/category/components/cms/useCategoryContent';
import { useTraverseCategory } from '~/modules/catalog/category/helpers/useTraverseCategory';
import CategoryBreadcrumbs from '~/modules/catalog/category/components/breadcrumbs/CategoryBreadcrumbs.vue';
import HTMLContent from '~/components/HTMLContent.vue';
import { ProductInterface } from '~/modules/GraphQL/types';
import facetGetters from '~/modules/catalog/category/getters/facetGetters';
import { chunk, merge } from 'lodash-es';
import { ProductConditionQuery } from '~/custom-api/api/productConditions/types';
import useFacet from '~/modules/catalog/category/composables/useFacet';
import { useProductConditions } from '~/composables';

export default defineComponent({
  name: 'IbexiaPage',
  components: {
    HTMLContent,
    CmsContent: () => import('~/modules/catalog/category/components/cms/CmsContent.vue'),
    PictimeCategoryProductList: () => import('~/modules/catalog/category/components/views/PictimeCategoryProductList.vue'),
    CategoryBreadcrumbs,
  },
  transition: 'fade',
  head(): MetaInfo {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true });

    let myLinks = [];
    const meta = [];

    i18nHead.link.forEach(element => {
      if (element.rel !== 'canonical') {
        myLinks.push(element);
      }
    });

    // Contenu externe
    let custom = this?.activeCategory?.custom_canonical_url;
    let title = this?.activeCategory?.meta_title;
    let metaDescription = this?.activeCategory?.meta_description;

    if (custom) {
      myLinks.push({
        hid: 'canonical',
        rel: 'canonical',
        href: custom
      })
    }

    meta.push(
      { hid: 'description', name: 'description', content: metaDescription },
      { hid: 'og:title', property: 'og:title', content: title },
      { hid: 'og:description', property: 'og:description', content: metaDescription },
      { hid: 'og:site_name', property: 'og:site_name', content: this.$t('site_name') },
    );

    return {
      title,
      link: [
        ...myLinks
      ],
      meta
    }
  },
  setup() {
    const { getContentData } = useCategoryContent();
    const { addTags } = useCache();
    const { app } = useContext();
    const { activeCategory, loadCategoryTree, isCategoryTreeLoaded } = useTraverseCategory();
    const { result, search } = useFacet();
    const { fetchProductConditions } = useProductConditions();
    const router = useRouter();
    const path = router.currentRoute.fullPath;
    const activeCategoryName = ref('');
    const metaDescription = activeCategory.value?.meta_description;
    const isShowProducts = ref(false);
    const seoBloc1 = ref('');
    const seoBloc2 = ref('');
    const cmsContent = ref('');
    const products = ssrRef<ProductInterface[]>([]);
    const categoryUid = ref('');

    const getExternalCategory = async () => {
      const contentCat = await getContentData(categoryUid.value as string, path);

      cmsContent.value = contentCat?.cmsBlock?.content ?? cmsContent.value;
      isShowProducts.value = contentCat.isShowProducts;
      seoBloc1.value = contentCat.seoBloc1 ?? '';
      seoBloc2.value = contentCat.seoBloc2 ?? '';
    }

    const fetchData = async () => {
      if (!activeCategory.value) {
        await loadCategoryTree();
      }

      if (activeCategory.value) {
        activeCategoryName.value = activeCategory.value.name ?? '';
        categoryUid.value = activeCategory.value.uid;
      }

      await getExternalCategory()

      if (isShowProducts.value) {
        await Promise.all([
          search({
            category_uid: categoryUid.value,
          }),
        ]);

        products.value = facetGetters.getProducts(result.value) ?? [];

        products.value = products.value.map(p => {
          return merge({}, { priceLoading: true }, p);
        });

        const token = app.$cookies.get('shop_token_id', { parseJSON: false });
        const tokenId = token ? token : '';
        await callProductConditions(tokenId);
      }
    }

    const callProductConditions = async (tokenId) => {
      let productsChunks = chunk(products.value, 6);
      for (const prods of productsChunks) {
        prods.forEach(prod => {
          prod.priceLoading = true;
        });

        let skus = prods.map(p => ({ sku: p.sku }));

        let priceStocks = await fetchProductConditions({
          tokenId: tokenId,
          products: skus
        });

        //Merge les données produits et les données priceStock pour tout avoir dans le produit
        products.value = products.value.map(product => {
          let priceItem: ProductConditionQuery = priceStocks.find(price => price.sku === product.sku);

          if (typeof priceItem !== 'undefined' && priceItem.sku === product.sku) {
            product.priceLoading = false;
          }

          if (priceStocks.length == 0) {
            product.priceLoading = false;
          }

          return merge({}, priceItem, product);
        });
      }
    };

    const trunckateTxt = function (blocSeo, btnReadMore) {
      if (blocSeo === null || blocSeo === undefined) {
        return;
      }

      let eltHeight = blocSeo.clientHeight;

      /* Calcule le nombre de ligne en fonction du font-size et du line-height */
      const eltFontSize = parseFloat(window.getComputedStyle(blocSeo, null).getPropertyValue('font-size'));
      let eltLineHeight = parseFloat(window.getComputedStyle(blocSeo, null).getPropertyValue('line-height'));

      if (isNaN(eltLineHeight)) {
        eltLineHeight = eltFontSize * 1.25;
      }

      const numberLine = Math.ceil(eltHeight / eltLineHeight);

      if (numberLine > 2) {
        blocSeo.classList.add('trunckated');
        btnReadMore.classList.remove('hidden');
      }

      btnReadMore.addEventListener('click', event => {
        blocSeo.classList.remove('trunckated');
        btnReadMore.classList.add('hidden');
      });
    };

    const setTags = async () => {
      products.value = facetGetters.getProducts(result.value) ?? [];

      const tags = [{ prefix: CacheTagPrefix.View, value: 'category' }];
      const productTags = products.value.map((product) => ({
        prefix: CacheTagPrefix.Product,
        value: product.uid,
      }));
      addTags([...tags, ...productTags]);
    };

    useFetch(async () => {
      await fetchData();
    });

    onSSR(async () => {
      await fetchData();
      await setTags();
    });

    onUpdated(async () => {
      const blocSeo1 = document.querySelector('.seo-block-1 p');
      const btnReadMore1 = document.getElementById('read-button-1');
      const blocSeo2 = document.querySelector('.seo-block-2 p');
      const btnReadMore2 = document.getElementById('read-button-2');

      trunckateTxt(blocSeo1, btnReadMore1);
      trunckateTxt(blocSeo2, btnReadMore2);
    });

    return {
      seoBloc1,
      seoBloc2,
      cmsContent,
      metaDescription,
      activeCategory,
      isCategoryTreeLoaded,
      categoryUid,
      products,
      isShowProducts
    };
  },
});
